.single-news {
	margin-top: 25px;
}

ul.inline-news {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin-bottom: .65em;
	}
	@media (max-width: $screen-xs-max) {
		li {
			padding-bottom: .65em;
			border-bottom: 1px solid #D9D9D9;
			font-size: 1.1em;
    	font-weight: bold;
		}
	}
}

.list-news {
	> ul {
		list-style: none;
		margin: 0;
		padding: 0;
		> li {
			@include make-row();
			margin-bottom: 15px;
			> figure {
				position: relative;
				@include make-sm-column(4);
				img {
					max-width: 100%;
				}
				i.fa.fa-play {
					color: #fff;
					background: red;
					padding: 3px 8px;
					font-size: 1em;
					border-radius: 3px;
					position: absolute;
					top: 5px;
					right: 20px;
				}
			}
			.featured {
				width: 40px;
		    height: 40px;
		    display: block;
		    background: #E43625;
		    font-size: 20px;
		    color: #fff;
		    border-radius: 100%;
		    text-align: center;
		    vertical-align: middle;
		    position: absolute;
		    bottom: 5px;
		    left: 20px;
		    z-index: 9;
				> i {
					vertical-align: middle;
					display: inline-block;
					margin-top: 9px;
				}
			}
			.main-content {
				@include make-sm-column(8);
				header.list-news-header {
					color: darken(#fff, 50%);
					> h1 {
						font-size: 1.2em;
						line-height: 1.5em;
						margin: 0px;
						color: darken(#fff, 80%);
						a {
							color: darken(#fff, 80%);
							font-weight: bold;
						}
					}
				}
				.summary {
					line-height: 1.5em;
				}
			}
			@media (max-width: $screen-xs-max) {
				> figure {
					img {
						width: 100%;
					}
				}
				.main-content {
					header.list-news-header {
						> h1 {
							margin-top: 15px;
						}
					}
				}
			}
			&.video-lists {
				padding: 5px 0px 15px;
				margin-bottom: 20px;
				ul.list-video {
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						display: block;
						margin: 0;
						@include make-sm-column(4);
						figure {
							position: relative;
							img {
								width: 100%;
							}
							i.fa.fa-play {
								color: #fff;
								background: red;
								padding: 3px 8px;
								font-size: 1em;
								border-radius: 3px;
								position: absolute;
								top: 5px;
								right: 5px;
							}
						}
						.featured {
							width: 40px;
					    height: 40px;
					    display: block;
					    background: #E43625;
					    font-size: 20px;
					    color: #fff;
					    border-radius: 100%;
					    text-align: center;
					    vertical-align: middle;
					    position: absolute;
					    bottom: 5px;
					    left: 5px;
					    z-index: 9;
							> i {
								vertical-align: middle;
								display: inline-block;
								margin-top: 9px;
							}
						}
						> h1 {
							font-size: 1em;
							line-height: 1.5em;
							margin: 10px 0px 0px;
							color: darken(#fff, 80%);
							a {
								color: darken(#fff, 80%);
							}
						}
						@media (max-width: $screen-sm-max) {
							> h1 {
								font-size: 1.2em;
								margin: 10px 0px 10px;
							}
						}
					}
				}
			}
		}
	}
	&.single-row {
		> ul {
			> li {
				> figure {
					@include make-md-column(12);
					img {
						width: 100%;
					}
				}
				.main-content {
					@include make-md-column(12);
					header.list-news-header {
						> h1 {
							margin-top: 5px;
						}
					}
				}
			}
		}
	}
	&.mobile-v {
		> ul > li {
			margin: 0;
		  padding: 10px 0;
		  border-bottom: 1px solid #ccc;
			header.list-news-header {
				color: gray;
				h1 {
					font-size: 1em;
			    line-height: 1.6em;
			    margin: 0px;
					a {
						color: #000;
						font-weight: bold;
					}
				}
			}
		}
	}
}

article.article-content {
	header.header-article {
		> h1.title {
			font-size: 2.5em;
		    font-weight: normal;
		    line-height: 1.2em;
		}
	}
	.main-content {
		margin-top: 35px;
    font-size: 1.1em;
	  line-height: 1.8em;
		img {
			max-width: 100%;
		}
	  p {
			margin-bottom: 1.8em;
	  }
	}
}
