.user-settings {
	padding-top: 100px;
	h1.title {
		margin: 0 0 1em;
	    padding-bottom: 0.5em;
	    font-size: 1.8em;
	    font-weight: normal;
	    border-bottom: 1px solid #eaeaea;
	}
	.classfield-menu {
	    background: #FBFBFB;
	    border: 1px solid #E6E6E6;
	    box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08);
    	li {
    		i.fa {
				margin: 0px 10px;
			}
    		a {
    			border-radius: 0px;
    			padding: 13px;
    			color: #636363;
    		}
    		&.active a {
    			background: #476D8E;
    			color: #fff;
    		}
    	}
	}
}