$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;
$container-large-desktop:      (1100px) !default;

$brand-primary: #CD3627;
$input-border-focus: $brand-primary;

$fbcolor: #3b5998;
$twcolor: #6AADD1;
$gpluscolor: #CD3627;
$pinterestcolor: #D30005;

$navbar-height: 60px;

$navbar-default-bg: darken(#fff, 1%);
$navbar-default-border: transparent;

// bugfix on mobile
html, body { overflow-x: hidden; }

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// default style
@import "includes/default";

@import "includes/landing";
@import "includes/auth";
@import "includes/news";
@import "includes/admin";
@import "includes/profile";
@import "includes/wiki";
@import "includes/user_settings";
@import "includes/pages";
@import "includes/fokus";
