.landing {
	.slide-content {
		background: lighten(#000, 15%);
		min-height: 400px;
	}
	.featured-news {
		@include make-md-column(2,0);
		position: relative;
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			@include make-sm-column(6,0);
		}
		figure {
			img {
				width: 100%;
			}
		}
		.list-news-header {
			h1.title {
				font-size: 1em;
				line-height: 1.3em;
				color: #fff !important;
				margin: 0;
			}
		}
		.main-content {
			position: absolute;
			bottom: 0;
			padding: 10px;
			width: 100%;
			@include gradient-vertical(rgba(0,0,0,0), rgba(0,0,0,1), 0%, 100%);
			padding: 15px;
		}
		a {
			&:hover {
				.main-content {
					@include gradient-vertical(rgba(0,0,0,0), rgba(0,0,0,0.9), 0%, 100%);
				}
			}
		}
	}
}

.sidebar-right {
	.ad-area {
		margin: 20px 0px;
		img {
			width: 100%;
		}
	}
}

.top-ads {
	.ad-area {
		margin: 20px 0;
		img {
			width: 100%;
		}
	}
}

.main-mobile-homepage {
	.featured-news {
		width: 100%;
	}
}

.featured-content {
	position: relative;
}

.nav-slide {
	a {
		color: #fff;
		position: absolute;
		bottom: 35%;
		z-index: 2505;
		font-size: 4em;
		margin: .25em;
	}
	#next-slide {
		right: 0;
	}
}

ul.list-main-fokus {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		overflow: hidden;
		padding: 5px 0px;
		border-bottom: 1px solid #e3e3e3;
		a {
			color: #323232;
			i.count {
				color: #fff;
				background: #f00;
				height: 30px;
				width: 30px;
				display: inline-block;
				text-align: center;
				font-weight: bold;
				padding-top: 5px;
				float: left;
			}
			span {
				display: block;
				margin-left: 40px;
				margin-top: 5px;
				font-weight: bold;
			}
		}
	}
}
