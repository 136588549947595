.auth-page {
	width: 440px;
	margin-top: 50px;
	> h1 {
		font-weight: normal;
		margin-bottom: 25px;
		font-size: 2em;
	}
	@media (max-width: $screen-sm-max) {
		width: 100%;
	}
}
