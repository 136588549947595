.profile-page {
	margin-top: 25px;
	.news-content {
		position: relative;
		&:before {
			content: '';
			display: block;
			top: 0;
			left: -30px;
			bottom: 0;
			position: absolute;
			border-left: 1px solid #d9d9d9;
		}
	}
	.user-info {
		margin-top: 20px;
	}
}