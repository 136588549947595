.admin-page {
	background: lighten(#000, 15%);

	.datetime-picker-outer  > i{
		position: absolute;
		margin: 8.5px 0px;
		font-size: 1.1em;
		color: #828282;
	}
	.datetime-picker {
		background: transparent;
		border: 0;
		padding: 0;
		box-shadow: none;
		text-align: right;
		cursor: pointer;
	}

	.user-info {
		background: darken($brand-primary, 10%);
		h1 {
			color: #fff;
			font-size: 1.5em;
			margin: 0;
			padding: 50px 15px 15px;
			font-weight: normal;
		}
	}
	.left-sidebar {
		padding: 0;

		.nav-pills {
			> li {
				i.fa {
					margin: 0px 10px;
				}
				> a {
					padding: 13px;
					border-radius: 0;
					color: #fff;
					&:hover {
						background: lighten(#000, 20%);
					}
				}
				ul.sub-menu {
					margin: 0;
				    padding: 0;
				    padding-left: 30px;
				    background: darken($brand-primary, 15%);
				    list-style: none;
				    display: none;
					> li {
						> a {
						    padding: 13px;
						    display: block;
						    color: #D8DDE2;
						    text-decoration: none;
						}
					}
				}
				&.active > {
					a {
						background: $brand-primary;
					}
					ul.sub-menu {
						display: block;
					}
				}
			}
		}
	}
	.right-content {
		background: #FDFDFD;
		min-height: 800px;
		padding-bottom: 100px;
		> h1 {
			font-size: 1.6em;
			font-weight: normal;
		    margin: 15px 0 10px;
		    border-bottom: 1px solid #E2E2E2;
		    padding-bottom: 10px;
		}
		h2 {
		    font-size: 1.2em;
    		margin: 0 0 1em;
		}
		.top-right-content {
		    margin-top: -50px;
    		float: right;
		}
		.top-group {
			margin-bottom: 10px;
		}
		.list-article, .list-fokus {
			background: #fff;
		    list-style: none;
		    padding: 0;
		    > li {
		    	padding: 15px;
    			border-bottom: 1px solid #eaeaea;
    			position: relative;
    			&:last-child {
    				border-bottom: 0;
    			}
    			.status {
    				position: absolute;
						top: 13px;
						right: 10px;
						background: #fff;
						padding-left: 0px;
						box-shadow: 0px 0px 10px 10px #fff;
    			}
    			h1.title {
    				font-size: 1.1em;
    				margin: 0;
    				a {
    					color: #333;
    					text-decoration: none;
    					&:hover {
    						color: lighten(#333, 10%);
    					}
    				}
    			}
    			p {
    				&.summary {
					    color: #D6CFCF;
					    margin-bottom: 5px;
					    margin-top: 5px;
					    span {
					    	color: #888
					    }
    				}
    			}
    			.footer {
   					margin: 0;
   					> a, div, div > a {
   						display: inline-block;
   						margin-right: 15px;
   						a:focus {
   							text-decoration: none;
   						}
   					}
   					a:hover, a:active, a:focus {
   						text-decoration: none;
   					}
    			}
		    }
		}
		.right-content-editor {
			.general-content {
				margin-bottom: 10px;
			}
		}
	}

	.main-admin-content {
		.list-group {
			.list-group-item {
				position: relative;
				.footer {
					a {
						margin-right: 10px;
					}
				}
				.status {
    				position: absolute;
					top: 10px;
					right: 10px;
    			}
			}
		}
	}

	.list-category {
		.top-content {
			margin: 15px 0px;
		}
	}

	.tags-input + .select2-container {
		margin-bottom: 13px;
	}

	.image-thumbnail-zone {
		background-color: #eaeaea;
		background-size: cover;
		background-position: center;
		height: 145px;
	}
	.portrait-zone {
		width: 145px;
	}
	.upload-btn {
		overflow: hidden;
		position: relative;
		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transform: scale(2);
		}
	}
	.arrange-category {
		ul.list-arrange {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				cursor: move;
				background: #fff;
				padding: 10px;
				box-shadow: 0px 1px 2px rgba(0,0,0,0.3);
				margin-bottom: 10px;
				font-weight: bold;
				position: relative;
				.delete, .edit {
					position: absolute;
					right: 10px;
				}
				.edit {
					right: 35px;
				}
				span {
					> i {
						width: 20px;
					}
				}
			}
		}
	}
	ul.list-wiki {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			overflow: hidden;
			figure {
				float: left;
				margin-right: 10px;
				img {
					width: 75px;
				}
			}
			h1 {
				font-size: 1.2em;
				color: #333;
				margin: 0;
			}
			border-bottom: 1px solid #eaeaea;
			padding: 15px;
			.action {
				a {
					display: inline-block;
					margin-right: 15px;
				}
			}
		}
	}
}
