.single-page {
  min-height: 500px;
}

.category {
  &.mobile-v {
    margin-top: 25px;
    .breadcrumb {
      margin: 0 10px;
    }
    .general-content {
      .c-header {
        margin: 0 10px;
      }
    }
  }
  &.search-view {
    min-height: 500px;
  }
}
