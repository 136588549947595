.fokus {
    min-height: 500px;
    .fokus-header {
        background: #151515;
        color: #fff;
        margin-top: 25px;
        padding: 25px;
        img {
            max-width: 100%;
        }
        h1 {
            margin: 0;
            font-size: 2em;
            font-weight: normal;
            border-bottom: 1px solid #4d4a4a;
            padding-bottom: 0.3em;
            margin-bottom: .3em;
        }
        p {
            color: rgb(165, 165, 165)
        }
    }
}
