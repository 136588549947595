.alphabeat {
	text-align: center;
	li {
		a {
			padding: 5px 10px !important;
		}
	}
	margin-bottom: 10px !important;
}

.wiki {
	min-height: 500px;
	.profile-item {
		figure {
			img {
				max-width: 100%;
			}
		}
		margin-bottom: $grid-gutter-width;
		h1 {
		    font-size: 1em;
		    margin: 0;
		    position: absolute;
		    bottom: 0;
		    background: rgba(0, 0, 0, 0.4);
		    left: 15px;
		    right: 15px;
		    padding: 10px;
		    color: #fff;
		}
		a:hover {
			h1 {
				background: rgba(0, 0, 0, 0.6);
			}
		}
	}
	&.wiki-profile {
		.fname {
			font-size: 1.3em;
			margin: 0 0 .8em;
		}
		p.w-detail {
			margin-bottom: 0;
			span:first-child {
				width: 115px;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
}
