// ADAM EDIT
.round-popular {
    background: #CD3627;
    border-radius: 40px;
    width: 30px;
    height: 30px;
    padding: 0;
    text-align: center;
    line-height: 30px;
    margin-top: 15px;
    border: 0px solid #444;
    color: #fff;
    font-weight: bold;
    @media (max-width: $screen-sm-max) {
      margin-left: 1em;
    }
}
// ini tolong dipatch diganti
#patch-1{
	font-size:1em;
  @media (max-width: $screen-sm-max) {
    font-size: 1.1em;
    margin-left: 3em;
    margin-top: -1.7em;
  }
}

// END ADAM EDIT


body {

}

.navbar-brand {
	margin: 0;
    padding: 5px;
    width: 205px;
	> img {
		max-height: 100%;
	}
}

.navbar-default .navbar-nav {
	> li {
		> a {
			text-transform: uppercase;
		    font-weight: bold;
		}
		&.active > a {
			box-shadow: 0px -3px 0px #F00 inset;
    		background: transparent;
    		&:hover {
    			background: transparent;
    		}
		}
	}
}

.fix-navbar-page {
	padding-top: 60px;
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		//padding-top: 120px;
	}
}

.form-control, .btn, .dropdown-menu, .list-group-item {
	border-radius: 0 !important;
}

.form-control {
	&:focus {
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		border-color: 1px solid $brand-primary;
	}
}


.navbar-form.navbar-right {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    width: 160px;
  }
}
.global-search-input {
	width: 300px !important;
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		width: 100% !important;
	}
}

ul.category-lists {
	background: lighten($brand-primary, 5%);
	margin: 1em 0 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			border-left: 1px solid #B53326;
			display: block;
			padding: 10px 15px;
			color: #fff;
			text-decoration: none;
			font-weight: bold;
			&:hover {
				background: $brand-primary;
			}
		}
    &:first-child {
      a {
        border-left: none;
      }
    }
		&.active a {
			background: $brand-primary;
		}
	}
	&.video {
		background: $brand-success;
		li {
      a {
        border-left: 1px solid darken($brand-success, 10%);
      }
			a:hover {
				background: darken($brand-success, 10%);
			}
			&.active a {
				background: darken($brand-success, 10%);
			}
      &:first-child {
        a {
          border-left: none;
        }
      }
		}
	}
	&.sub-category {
		background: #f0f0f0;
		border-bottom: transparent;
		margin-top: 0em;
		margin-bottom: 1em;
		li {
			a {
				font-size: 12px;
				color: lighten(#000, 25%);
				border-left: none;
		    border-right: none;
        font-weight: bold;
        padding: 2px 15px;
				&:hover {
					color: lighten(#000, 50%);
					background: transparent;
				}
			}
		}
	}
  @media (max-width: $screen-sm-max) {
    @include make-row();
    margin: {
      left: 0;
      right: 0;
    }
    li {
      @include make-xs-column(6, 0);
      a {
        border-left: none;
        text-align: center;
        padding: 15px;
        box-shadow: -1px -1px 0px 0px rgba(0, 0, 0, 0.2) inset
      }
    }
  }
}

.general-content {
	//background: #eaeaea;
	.c-header {
		h1 {
			font-size: 1.2em;
			text-transform: uppercase;
		}
		border-bottom: 1px solid darken(#fff, 15%);
		margin-bottom: 1em;
		&.w-tab {
			margin-top: 20px;
		    border: none;
		    text-transform: uppercase;
		    font-weight: bold;
		    font-size: 0.85em;
		}
	}
}

.btn-share {
	margin: 20px 0;
	.btn {
		&-facebook {
			@include button-variant(#fff, $fbcolor, $fbcolor);
		}
		&-twitter {
			@include button-variant(#fff, $twcolor, $twcolor);
		}
		&-google {
			@include button-variant(#fff, $gpluscolor, $gpluscolor);
		}
		&-pinterest {
			@include button-variant(#fff, $pinterestcolor, $pinterestcolor);
		}
	}
  @media (max-width: $screen-xs-max) {
    .btn {
      font-size: 0px;
      > i {
        font-size: 20px;
      }
    }
  }
}

footer#site-footer {
	margin-top: 50px;
	padding: 15px 0px 50px;
	background: lighten(#000, 10%);
	color: #fff;
	.footer-content {
		h1.title {
			font-size: 1em;
			text-transform: uppercase;
		}
		.content {
			margin-top: 1em;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 0.3em;
				a {
					color: #fff;
				}
			}
		}
	}
}

.magic-ads {
  @media (max-width: $screen-sm-max) {
    @include make-row();
    .ad-area {
      @include make-sm-column(6);
    }
  }
}

.navbar-header {
  .navbar-toggle {
    border-color: transparent;
    &:hover, &:active {
      background: transparent;
    }
  }
  @media (max-width: $screen-xs-max) {
    overflow: hidden;
    text-align: center;
    .navbar-brand {
      display: inline-block;
      float: none;
      margin-left: -50px;
    }
  }
}

.mobile-pagination {
  display: none;
  @media (max-width: $screen-xs-max) {
    display: block;
  }
}
.loadmore-btn {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

/**
 * For mobile menu version
 */
.extern-menu {
  position: fixed;
  z-index: 20511;
  background: #171717;
  height: 100%;
  width: 70%;
  overflow-y: auto;
  left: -70%;
  transition: all 0.3s;
  &.active {
    left: 0;
  }
  a.close-x-menu {
    color: #fff;
    float: right;
    font-size: 1.5em;
    margin-right: .6em;
    margin-top: .25em;
  }
  nav.mobile-nav {
    margin-right: 40px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          color: #fff;
          display: block;
          font-size: 1.1em;
          padding: .5em 1em;
          font-weight: bold;
          text-decoration: none;
        }
        &.s-title {
          font-size: 1em;
          padding: .5em 0em;
          color: #6D6D6D;
          text-transform: uppercase;
          font-weight: bold;
          border-top: 1px solid #404040;
          margin: .5em 1em 0;
        }
      }
    }
  }
}

.breadcrumb {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mobile-search-box {
  margin: 0 15px -45px;
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
